import { CircularProgress, Switch, TextareaAutosize } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Toast, ToastBody } from "react-bootstrap";
import toast from "react-hot-toast";
import { ManageWebAppApiServices } from "../../../../utils/api/ManageWebAppApiServices";
import { ManualPayment } from "../../../../utils/api/ManualPayment";
import "./GameMassage.scss";

const GameMassage = () => {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [state, setState] = useState({
    isLoading: false,
    isLoadingGetMessage: false,
    warningMessage: "",
    switch: true,
  });

  const onChangeWarning = (e) => {
    setState({
      ...state,
      warningMessage: e?.target?.value,
    });
  };

  const getMessageApi = async () => {
    setState({
      ...state,
      isLoadingGetMessage: true,
    });
    const res = await ManualPayment?.getWebAppSettings();

    console.log("game-res>>>", res);
    if (res?.data?.success) {
      setState({
        ...state,
        warningMessage: res?.data?.data?.game_message?.metadata?.message,
        isLoadingGetMessage: false,
        switch: res?.data?.data?.game_message?.enable,
      });
    } else {
      // alert("Internal server error!");
      toast.error("Internal server error!");
    }
  };

  const onClickMessageHandler = async () => {
    setState({
      ...state,
      isLoading: true,
    });

    const updateData = {
      enable: state?.switch,
      message: state?.warningMessage,
      setting_type: "game_message",
    };

    const res = await ManageWebAppApiServices?.updateGameWarning(updateData);

    if (res?.data?.success) {
      toast.success("Settings are updated successfully!");
    } else {
      toast.error("Internal server error!");
    }
    setState({
      ...state,
      isLoading: false,
    });
  };

  const onChangeSwitchHandler = (e) => {
    if (state.switch) {
      setState({
        ...state,
        switch: false,
      });
    } else {
      setState({
        ...state,
        switch: true,
      });
    }
  };

  console.log("state>>>", state);

  useEffect(() => {
    getMessageApi();
  }, []);
  return (
    <div className="game_web_main_container">
      <div className="toggle_switch_container text-muted font-weight-normal">
        <p>Manage Game Message</p>
        {state.isLoadingGetMessage ? (
          ""
        ) : (
          <Switch
            {...label}
            checked={state?.switch}
            onChange={onChangeSwitchHandler}
            // defaultValue={state?.switch}
            // key={}
          />
        )}
      </div>
      <div className="text_area_container">
        <div>
          <label>Warning message</label>
          <TextareaAutosize
            aria-label="empty textarea"
            placeholder={
              state.isLoadingGetMessage
                ? "Message feching..."
                : "Enter your message"
            }
            className="text_area"
            minRows={5}
            maxRows={12}
            onChange={onChangeWarning}
            value={state?.warningMessage}
            disabled={state?.isLoadingGetMessage}
          />
        </div>
        <button
          onClick={onClickMessageHandler}
          disabled={state?.isLoadingGetMessage}
        >
          {state.isLoading ? (
            <CircularProgress thickness={4} style={{ width: 30, height: 30 }} />
          ) : (
            "Save"
          )}
        </button>
      </div>
    </div>
  );
};

export default GameMassage;
