import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { gameManagerAction } from "../../../redux/features/gameManagerSlice";
import { ManualPayment } from "../../../utils/api/ManualPayment";
import { PaymentStatus } from "../../../utils/common.utils";
import "./ManualPaymentsList.scss";

const PaymentActionBtn = ({ status, id }) => {
  const [state, setState] = useState({
    isLoadingAccept: false,
    isLoadingReject: false,
  });

  const dispatch = useDispatch();

  const onClickApprove = async () => {
    setState({
      ...state,
      isLoadingAccept: true,
    });

    let updateData = {
      status: PaymentStatus?.APPROVED,
      payment_id: id,
    };
    const res = await ManualPayment?.updatePaymentStatus(updateData);

    console.log("state-res>>>", res);
    if (res?.data?.success) {
      toast.success("Payment approved successfully!");
      dispatch(gameManagerAction.resetPaymentList());
    } else {
      // alert("Internal server error!");
      toast.error(res?.data?.data?.message || "Internal server error!");
    }

    setState({
      ...state,
      isLoadingAccept: false,
    });
  };

  const onClickReject = async () => {
    setState({
      ...state,
      isLoadingReject: true,
    });

    let updateData = {
      status: PaymentStatus?.REJECTED,
      payment_id: id,
    };
    const res = await ManualPayment?.updatePaymentStatus(updateData);

    console.log("state-res>>>", res);
    if (res?.data?.success) {
      toast.success("Payment rejected successfully!");
      dispatch(gameManagerAction.resetPaymentList());
    } else {
      // alert("Internal server error!");
      toast.error(res?.data?.data?.message || "Internal server error!");
    }

    setState({
      ...state,
      isLoadingReject: false,
    });
  };
  return (
    <>
      {status === PaymentStatus?.PENDING ? (
        <div className="payment_btn">
          <button
            className={`action_btn approve_btn mr-3 `}
            onClick={onClickApprove}
          >
            {state?.isLoadingAccept ? (
              <CircularProgress
                style={{ color: "white", width: 20, height: 20 }}
              />
            ) : (
              "Approve"
            )}
          </button>
          <button className="action_btn reject_btn" onClick={onClickReject}>
            {state?.isLoadingReject ? (
              <CircularProgress
                style={{ color: "white", width: 20, height: 20 }}
              />
            ) : (
              "Reject"
            )}
          </button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default PaymentActionBtn;
