import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { ManualPayment } from "../../../utils/api/ManualPayment";
import { PaymentStatus } from "../../../utils/common.utils";

import "./Transactions.scss";

const DepositActionButtons = ({ status, id, user_id, handleSuccess }) => {
  const [state, setState] = useState({
    isLoadingAccept: false,
    isLoadingReject: false,
  });

  const dispatch = useDispatch();

  const rejectPayment = async () => {
    setState({
      ...state,
      isLoadingReject: true,
    });

    const res = await ManualPayment.rejectDepositPayment({
      // transaction_id: id?.trim(),
      transaction_id: id?.trim(),
    });

    if (!res.success) {
      toast.error(
        res?.data?.data?.message ||
          "Something went wrong, payment is not rejected!"
      );

      setState({
        ...state,
        isLoadingReject: false,
      });

      return;
    }

    toast.success("Payment rejected successfully!");

    setState({
      ...state,
      isLoadingReject: false,
    });

    handleSuccess();
  };

  const approvePayment = async () => {
    setState({
      ...state,
      isLoadingAccept: true,
    });

    const res = await ManualPayment.approveDepositPayment({
      // transaction_id: id?.trim(),
      transaction_id: id?.trim(),
      user_id: user_id,
    });

    if (!res.success) {
      toast.error(
        res?.data?.data?.message ||
          "Something went wrong, payment is not approved!"
      );

      setState({
        ...state,
        isLoadingAccept: false,
      });

      return;
    }

    toast.success("Payment approved successfully!");

    setState({
      ...state,
      isLoadingAccept: false,
    });

    handleSuccess();
  };

  return (
    <>
      {status === PaymentStatus?.DEPOSIT_PENDING ? (
        <div className="payment_btn">
          <button
            className={`action_btn approve_btn mr-3 `}
            onClick={approvePayment}
          >
            {state?.isLoadingAccept ? (
              <CircularProgress
                style={{ color: "white", width: 20, height: 20 }}
              />
            ) : (
              "Approve"
            )}
          </button>
          <button className="action_btn reject_btn" onClick={rejectPayment}>
            {state?.isLoadingReject ? (
              <CircularProgress
                style={{ color: "white", width: 20, height: 20 }}
              />
            ) : (
              "Reject"
            )}
          </button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default DepositActionButtons;
