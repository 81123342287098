import React, { useState } from "react";
import "./GameManager.scss";
import { CircularProgress, Switch } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useDispatch } from "react-redux";
import { gameManagerAction } from "../../../redux/features/gameManagerSlice";
import { getPaymentScreenshotUrl } from "../../../utils/common.utils";
import { GameApiServices } from "../../../utils/api/GameApiServices";
import toast from "react-hot-toast";

const GameCard = ({ data }) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    isLoadingDelete: false,
    gameCheckBox: data?.enable,
  });

  const onClickEdit = () => {
    dispatch(gameManagerAction.setEditGameModal());
    dispatch(
      gameManagerAction.setEditGameData({
        id: data?._id,
        image_url: data?.thumbnail_url,
        game_name: data?.game_name,
      })
    );
  };

  const onClickDelete = async () => {
    setState({
      ...state,
      isLoadingDelete: true,
    });

    const res = await GameApiServices?.deleteGames(data?._id);
    console.log("state-res>>>", res);
    if (res?.data?.success) {
      setState({
        ...state,
        isLoadingDelete: false,
      });
      toast.success("Game deleted successfully!");
      dispatch(gameManagerAction?.resetGameList());
    } else {
      toast.error(
        res?.data.data?.message || "Game not found!" || "Internal server error!"
      );
    }
  };

  const onChangeWithdrawSwitch = async () => {
    setState({
      ...state,
      isLoading: true,
      gameCheckBox: !state?.gameCheckBox,
    });

    let formData = new FormData();

    formData.append("enable", !state?.gameCheckBox);
    const res = await GameApiServices?.updateGame(formData, data?._id);
    console.log("state-res>>>", res);
    if (res?.data?.success) {
      toast.success("Game status change successfully!");
      dispatch(gameManagerAction?.resetGameList());
    } else {
      toast.error(res?.data.data?.message || "Internal server error!");
    }
    setState({
      ...state,
      isLoading: false,
    });
  };
  return (
    <div className="game_card" key={data?._id}>
      <div className="game_name">
        <p>{data?.game_name}</p>
      </div>
      <div className="game_image">
        <img src={getPaymentScreenshotUrl(data?.thumbnail_url)} />
      </div>
      <div className="game_action">
        <div className="game_switch">
          <Switch
            onChange={onChangeWithdrawSwitch}
            checked={state?.gameCheckBox}
          />
        </div>
        <div className="action_button">
          <div className="icon" onClick={onClickEdit}>
            <ModeEditOutlineOutlinedIcon />
          </div>
          <div className="icon" onClick={onClickDelete}>
            {state?.isLoadingDelete ? (
              <CircularProgress
                thickness={3}
                style={{ width: 15, height: 15 }}
              />
            ) : (
              <DeleteOutlineOutlinedIcon />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameCard;
