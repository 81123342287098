export class PaymentStatus {
  static DRAFT = "draft";
  static FINALIZED = "finalized";
  static PENDING = "pending";
  static INCOMING = "incoming";
  static ACCEPTED = "accepted";
  static ACTIVE = "active";
  static REJECTED = "rejected";
  static UNANSWERED = "unanswered";
  static INAPPROPRIATE = "INAPPROPRIATE";
  static CANCELLED = "cancelled";
  static APPROVED = "approved";

  static DEPOSIT_PENDING = "Pending";
}

export const getPaymentScreenshotUrl = (url) => {
  let live_url = process.env.REACT_APP_BACKEND_LIVE_API;
  let updateUrl = `${live_url}${url}`;
  return updateUrl;
};
