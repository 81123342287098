import { CircularProgress, Switch, TextareaAutosize } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Toast, ToastBody } from "react-bootstrap";
import toast from "react-hot-toast";
import "./GeneralSettings.scss";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import TimesOneMobiledataOutlinedIcon from "@mui/icons-material/TimesOneMobiledataOutlined";

const WithdrawAndDeposits = ({ formik }) => {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [state, setState] = useState({
    isLoading: false,
    isLoadingGetData: false,
  });

  const onChangeWithdrawSwitch = () => {
    if (formik?.values?.withdrawChechBox) {
      formik.setFieldValue("withdrawChechBox", false);
    } else {
      formik.setFieldValue("withdrawChechBox", true);
    }
  };

  const onChangeDepositSwitch = () => {
    if (formik?.values?.depositChechBox) {
      formik.setFieldValue("depositChechBox", false);
    } else {
      formik.setFieldValue("depositChechBox", true);
    }
  };
  return (
    <>
      <div className="withdraw_container contain">
        <div className="text_heading text-muted font-weight-normal">
          <p>Withdraw</p>
          <Switch
            {...label}
            // defaultChecked={state?.switch}
            onChange={onChangeWithdrawSwitch}
            // defaultValue={state?.switch}
            // key={}
            checked={formik?.values?.withdrawChechBox}
          />
        </div>
        <div className="body_container commission_referral_body">
          <div className="number_input_container">
            <label>Withdraw limit</label>
            <div className="input_and_icon">
              <input
                className="number_input border"
                placeholder="Enter your withdraw limit"
                value={formik?.values?.withdrawLimit}
                onChange={formik?.handleChange}
                name="withdrawLimit"
                type="number"
              ></input>
              <div className="percentage_icon">
                <TimesOneMobiledataOutlinedIcon />
              </div>
            </div>
          </div>
          <div className="number_input_container">
            <label>Withdraw amount</label>
            <div className="input_and_icon">
              <input
                className="number_input border"
                placeholder="Enter your withdraw amount"
                value={formik?.values?.withdrawAmount}
                name="withdrawAmount"
                onChange={formik?.handleChange}
                type="number"
              ></input>
              <div className="percentage_icon">
                <CurrencyRupeeOutlinedIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="text_area_contain">
          <label>Withdraw Update message</label>
          <TextareaAutosize
            name="withdrawMassage"
            aria-label="empty textarea"
            placeholder="Enter your message"
            className="text_area"
            minRows={5}
            maxRows={12}
            onChange={formik?.handleChange}
            value={formik?.values?.withdrawMassage}
          />
        </div>
      </div>

      <div className="deposit_container contain">
        <div className="text_heading text-muted font-weight-normal">
          <p>Deposit</p>
          <Switch
            {...label}
            onChange={onChangeDepositSwitch}
            checked={formik?.values?.depositChechBox}
          />
        </div>

        <div className="text_area_contain">
          <label>Deposit Update message</label>
          <TextareaAutosize
            name="depositMassage"
            aria-label="empty textarea"
            placeholder="Enter your message"
            className="text_area"
            minRows={5}
            maxRows={12}
            onChange={formik?.handleChange}
            value={formik?.values?.depositMassage}
          />
        </div>
      </div>
    </>
  );
};

export default WithdrawAndDeposits;
