import { CircularProgress, Switch, TextareaAutosize } from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ManualPayment } from "../../../utils/api/ManualPayment";
import { getPaymentScreenshotUrl } from "../../../utils/common.utils";

import "./PaymentSetting.scss";

const PaymentSetting = ({ formik }) => {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [state, setState] = useState({
    isLoading: false,
    isLoadingGetData: false,
    switch: true,
    newQrImage: null,
    apiImageUrl: "",
    upiId: "",
    paymentData: null,
    isLoadingDelete: false,
    reloadSettings: false,
  });

  const onChangeId = (e) => {
    setState({
      ...state,
      upiId: e?.target?.value,
    });
  };
  const onChangeImageHandler = (e) => {
    if (e?.target?.files.length > 0) {
      setState({
        ...state,
        newQrImage: e?.target?.files[0],
      });
    }
  };

  const onChangeBonusSwitch = () => {
    if (state?.switch) {
      setState({
        ...state,
        switch: false,
      });
    } else {
      setState({
        ...state,
        switch: true,
      });
    }
  };

  const onSubmitHandler = () => {
    let imageCheck = state.apiImageUrl || state.newQrImage !== null;
    if (imageCheck && state?.upiId) {
      updateSettingsApi();
    } else {
      let upiId = document.getElementById("upi_error_para_id");
      let qrCode = document.getElementById("qr_code_error_para_id");

      if (!state?.upiId) {
        upiId.classList.add("error_para_opacity");
        setTimeout(() => {
          upiId.classList.remove("error_para_opacity");
        }, 3000);
      }

      if (!state?.apiImageUrl && state?.newQrImage === null) {
        qrCode.classList.add("error_para_opacity");
        setTimeout(() => {
          qrCode.classList.remove("error_para_opacity");
        }, 3000);
      }
    }
  };

  const updateSettingsApi = async () => {
    setState({
      ...state,
      isLoading: true,
    });

    let formData = new FormData();
    formData.append("enable", state?.switch);
    formData.append("upi_id", state?.upiId);

    if (state?.newQrImage !== null) {
      formData.append("qr_image", state?.newQrImage);
    }
    const res = await ManualPayment?.updatePaymentSetting(formData);
    console.log("state-res>>>", res);
    if (res?.data?.success) {
      toast.success("Settings are updated successfully!");
    } else {
      toast.error(res?.data.data?.message || "Internal server error!");
    }

    setState({
      ...state,
      isLoading: false,
    });
  };

  const onClickDeleteLocal = () => {
    setState({
      ...state,
      newQrImage: null,
    });
  };

  const onClickDeleteApi = async () => {
    setState({
      ...state,
      isLoadingDelete: true,
    });

    const res = await ManualPayment?.deleteQrImage();
    console.log("state-res>>>", res);
    if (res?.data?.success) {
      toast.success("payment settings delete successfully!");
      setState({
        ...state,
        isLoadingDelete: false,
        reloadSettings: !state?.reloadSettings,
      });
    } else {
      toast.error(res?.data.data?.message || "Internal server error!");
      setState({
        ...state,
        isLoadingDelete: false,
      });
    }

    setState({
      ...state,
      isLoadingDelete: false,
    });
  };

  const getDataApi = async () => {
    setState({
      ...state,
      isLoadingGetData: true,
    });
    const res = await ManualPayment?.getWebAppSettings();

    console.log("state-res>>>", res);
    if (res?.data?.success) {
      let payment_settings = res?.data.data?.payment_settings?.metadata;
      setState({
        ...state,
        paymentData: res?.data.data?.payment_settings,
        isLoadingGetData: false,
        apiImageUrl: payment_settings?.qr_image,
        upiId: payment_settings?.upi_id,
        switch: payment_settings?.enable === "true" ? true : false,
      });
    } else {
      // alert("Internal server error!");
      toast.error(res?.data.data?.message || "Internal server error!");
    }
  };

  useEffect(() => {
    getDataApi();
  }, [state?.reloadSettings]);

  return (
    <>
      {state?.isLoadingGetData ? (
        <div className="loading_container">
          <CircularProgress thickness={3} style={{ width: 40, height: 40 }} />
          <p>Fetching settings...</p>
        </div>
      ) : (
        <div className="payment_setting_container">
          <div className="text_heading text-muted font-weight-normal">
            <p>Payment Setting</p>
            <Switch
              {...label}
              onChange={onChangeBonusSwitch}
              checked={state?.switch}
            />
          </div>
          <div className="body_container commission_referral_body">
            <div className="number_input_container">
              <label>UPI ID*</label>
              <div className="input_and_icon">
                <input
                  className="number_input border"
                  placeholder="Enter your upi id"
                  value={state?.upiId}
                  onChange={onChangeId}
                ></input>
              </div>
              <p className="error_para" id="upi_error_para_id">
                UPI id is required!
              </p>
            </div>
            <div className="image_upload_container">
              <label>Upload QR*</label>
              {state?.apiImageUrl ? (
                <div className="qr_image_container">
                  <div className="delete_icon" onClick={onClickDeleteApi}>
                    {state.isLoadingDelete ? (
                      <CircularProgress
                        thickness={3}
                        style={{ width: 20, height: 20 }}
                      />
                    ) : (
                      <DeleteOutlineIcon />
                    )}
                  </div>

                  <img
                    src={getPaymentScreenshotUrl(state?.apiImageUrl)}
                    alt="QR image"
                  ></img>
                </div>
              ) : state?.newQrImage ? (
                <div className="qr_image_container">
                  <div className="delete_icon" onClick={onClickDeleteLocal}>
                    <DeleteOutlineIcon />
                  </div>
                  <img
                    src={URL.createObjectURL(state?.newQrImage)}
                    alt="QR image"
                  ></img>
                </div>
              ) : (
                <>
                  <div className="image_upload">
                    <input
                      className="number_input border"
                      placeholder="Enter your upi id"
                      // value={formik?.values?.commission}
                      // onChange={formik?.handleChange}
                      name="commission"
                      type="file"
                      onChange={onChangeImageHandler}
                    ></input>
                    <div className="upload_icon">
                      <p>Upload QR</p>
                      <DriveFolderUploadIcon />
                    </div>
                  </div>
                  <p className="error_para" id="qr_code_error_para_id">
                    QR code is required!
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="button_container">
            <button type="submit" onClick={onSubmitHandler}>
              {state.isLoading ? (
                <CircularProgress
                  thickness={4}
                  style={{ width: 30, height: 30 }}
                />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentSetting;
