import { CircularProgress, Switch, TextareaAutosize } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Toast, ToastBody } from "react-bootstrap";
import toast from "react-hot-toast";
import "./GeneralSettings.scss";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";

const GameBattleLimit = ({ formik }) => {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [state, setState] = useState({
    isLoading: false,
    isLoadingGetData: false,
    warningMessage: "",
    switch: true,
  });

  const onChangeWarning = (e) => {
    setState({
      ...state,
      warningMessage: e?.target?.value,
    });
  };
  return (
    <>
      <div className="commission_container contain">
        <div className="text_heading text-muted font-weight-normal">
          <p>Game Battles Limit</p>
        </div>
        <div className="body_container commission_referral_body">
          <div className="number_input_container">
            <label>Battles Limit</label>
            <div className="input_and_icon">
              <input
                className="number_input border"
                placeholder="Enter your battles limit"
                value={formik?.values?.gameBattleLimit}
                onChange={formik?.handleChange}
                name="gameBattleLimit"
                type="number"
              ></input>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GameBattleLimit;
