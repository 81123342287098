import axiosInstance from "./Axios";

export class ManageWebAppApiServices {
  static getWarning = async () => {
    try {
      const res = await axiosInstance({
        method: "get",
        url: "api/web-app-settings/waring/get-details",
      });

      return {
        data: res.data,
      };
    } catch (err) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static updateGameWarning = async (data) => {
    try {
      const res = await axiosInstance({
        method: "post",
        url: "api/web-app-settings/save",
        data,
      });

      return {
        data: res.data,
      };
    } catch (err) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static updateWarning = async (data) => {
    try {
      const res = await axiosInstance({
        method: "post",
        url: "api/web-app-settings/waring/update",
        data,
      });

      return {
        data: res.data,
      };
    } catch (err) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static updateWebSettings = async (data) => {
    try {
      const res = await axiosInstance({
        method: "post",
        url: "api/web-app-settings/save",
        data,
      });

      return {
        data: res.data,
      };
    } catch (err) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };
}
