import React from "react";
import GameMassage from "./GameMassage";
import HomeMassage from "./HomeMassage";
import "./WarningWeb.scss";

const WarningWeb = () => {
  return (
    <>
      <HomeMassage />
      <GameMassage/>
    </>
  );
};

export default WarningWeb;
