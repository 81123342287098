import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import "./GameManager.scss";

import GameCard from "./GameCard";
import CreateGameModal from "./CreateGameModal";
import { useDispatch } from "react-redux";
import { gameManagerAction } from "../../../redux/features/gameManagerSlice";
import { GameApiServices } from "../../../utils/api/GameApiServices";
import toast from "react-hot-toast";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const GamesList = () => {
  const [state, setState] = useState({
    isLoading: false,
    gamesList: [],
  });
  const dispatch = useDispatch();

  const onClickAddGame = () => {
    dispatch(gameManagerAction.setCreateGameModal());
  };

  const resetList = useSelector((state) => state?.gameManager?.resetGameList);

  const getListApi = async () => {
    setState({
      ...state,
      isLoading: true,
    });

    const res = await GameApiServices?.getGames();
    console.log("state-res>>>", res);
    if (res?.data?.success) {
      setState({
        ...state,
        isLoading: false,
        gamesList: res?.data?.data?.docs,
      });
    } else {
      toast.error(res?.data.data?.message || "Internal server error!");
    }
  };

  useEffect(() => {
    getListApi();
  }, [resetList]);

  return (
    <>
      <div className="game_list_container">
        <div className="col-12 grid-margin">
          <div className="game_header">
            <h4 className="card-title text-dark">Games list</h4>
            <button onClick={onClickAddGame}>Add game</button>
          </div>

          {state?.isLoading ? (
            <div className="loading_container">
              <CircularProgress
                thickness={3}
                style={{ width: 40, height: 40 }}
              />
              <p>Fetching games...</p>
            </div>
          ) : (
            <div className="game_list_body">
              <div className="games_list_container">
                {state?.gamesList.map((data) => (
                  <GameCard data={data} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GamesList;
