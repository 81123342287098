import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import "./CreateGameModal.scss";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { gameManagerAction } from "../../../redux/features/gameManagerSlice";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { getPaymentScreenshotUrl } from "../../../utils/common.utils";
import { GameApiServices } from "../../../utils/api/GameApiServices";
import toast from "react-hot-toast";

const EditGameModal = () => {
  const [state, setState] = React.useState({
    isLoading: false,
    imageFile: null,
    gameName: "",
  });

  const dispatch = useDispatch();

  const editGameModal = useSelector(
    (state) => state?.gameManager?.editGameModal
  );

  const editGameData = useSelector((state) => state?.gameManager?.editGameData);

  const handleClose = () => {
    dispatch(gameManagerAction.setEditGameModal());
    dispatch(
      gameManagerAction.setEditGameData({
        id: "",
        image_url: "",
        game_name: "",
      })
    );

    setState({
      ...state,
      imageFile: null,
    });
  };

  const onChangeImageHandler = (e) => {
    if (e?.target?.files.length > 0) {
      setState({
        ...state,
        imageFile: e?.target?.files[0],
      });
    }
  };

  const onChangeTextHandler = (e) => {
    setState({
      ...state,
      gameName: e?.target?.value,
    });
  };

  const onClickDelete = () => {
    dispatch(gameManagerAction.deleteGameImage());
  };

  const getScreenshotLink = (linkString) => {
    let updateLink = linkString;

    if (linkString?.length > 40) {
      let result = updateLink?.slice(0, 40);
      updateLink = `${result}...`;
    }

    return updateLink;
  };

  const editGameApi = async () => {
    setState({
      ...state,
      isLoading: true,
    });

    let formData = new FormData();
    formData.append("game_name", state?.gameName);

    if (state?.imageFile !== null) {
      formData.append("game_image", state?.imageFile);
    }

    const res = await GameApiServices?.updateGame(formData, editGameData?.id);
    console.log("state-res>>>", res);
    if (res?.data?.success) {
      toast.success("Game updated successfully!");
      handleClose();
      dispatch(gameManagerAction?.resetGameList());
    } else {
      toast.error(res?.data.data?.message || "Internal server error!");
    }

    setState({
      ...state,
      isLoading: false,
    });
  };

  const onClickSubmit = () => {
    let gameImgaeCheck = state?.imageFile !== null || editGameData.image_url;
    if (state?.gameName && gameImgaeCheck) {
      editGameApi();
    } else {
      let gameNameId = document.getElementById("edit_game_name_error_para_id");
      let imageFileId = document.getElementById(
        "edit_game_image_error_para_id"
      );

      if (!state?.gameName) {
        gameNameId.classList.add("error_para_opacity");
        setTimeout(() => {
          gameNameId.classList.remove("error_para_opacity");
        }, 3000);
      }

      if (state?.imageFile === null && !editGameData.image_url) {
        imageFileId.classList.add("error_para_opacity");
        setTimeout(() => {
          imageFileId.classList.remove("error_para_opacity");
        }, 3000);
      }
    }
  };

  React.useEffect(() => {
    setState({
      ...state,
      gameName: editGameData?.game_name,
    });
  }, [editGameData]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={editGameModal}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Modal title
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <div className="modal_body">
          <div className="number_input_container">
            <label>Game name*</label>

            <input
              className="number_input border"
              placeholder="Enter your game name"
              value={state?.gameName}
              onChange={onChangeTextHandler}
              name="withdrawLimit"
              type="text"
            ></input>
            <p className="error_para" id="edit_game_name_error_para_id">
              Game name is required!
            </p>
          </div>

          <div className="file_upload">
            <label>Image upload*</label>

            {editGameData?.image_url ? (
              <div className="image_upload_box">
                <p>
                  <a
                    href={getPaymentScreenshotUrl(editGameData?.image_url)}
                    target="_blank"
                  >
                    {getScreenshotLink(
                      getPaymentScreenshotUrl(editGameData?.image_url)
                    )}
                  </a>
                </p>
                <div className="icon" onClick={onClickDelete}>
                  <DeleteOutlineOutlinedIcon />
                </div>
              </div>
            ) : (
              <input
                className="number_input border"
                placeholder="Enter your game name"
                // value={formik?.values?.withdrawLimit}
                onChange={onChangeImageHandler}
                name="withdrawLimit"
                type="file"
              ></input>
            )}
            <p className="error_para" id="edit_game_image_error_para_id">
              Game image is required!
            </p>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="button_container">
          <button onClick={onClickSubmit}>
            {state.isLoading ? (
              <CircularProgress
                thickness={4}
                style={{ width: 25, height: 25 }}
              />
            ) : (
              "Edit"
            )}
          </button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default EditGameModal;
