import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createGameModal: false,
  editGameModal: false,
  resetPaymentList: false,
  resetGameList: false,

  editGameData: {
    id: "",
    image_url: "",
    game_name: "",
  },
};

export const gameManagerSlice = createSlice({
  name: "gameManager",
  initialState,
  reducers: {
    setCreateGameModal: (state) => {
      state.createGameModal = !state.createGameModal;
    },

    setEditGameModal: (state) => {
      state.editGameModal = !state.editGameModal;
    },

    resetGameList: (state) => {
      state.resetGameList = !state.resetGameList;
    },

    resetPaymentList: (state) => {
      state.resetPaymentList = !state.resetPaymentList;
    },

    setEditGameData: (state, action) => {
      state.editGameData = action.payload;
    },
    deleteGameImage: (state) => {
      state.editGameData = {
        ...state?.editGameData,
        image_url: "",
      };
    },

    // setLinkedIn: (state, action: PayloadAction<ISetlinkedInProfile>) => {
    //   state.linkedIn = action?.payload?.data;
    // },
  },
});

export const gameManagerAction = gameManagerSlice.actions;

export const gameManagerReducer = gameManagerSlice.reducer;
