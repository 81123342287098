import { CircularProgress, Switch, TextareaAutosize } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Toast, ToastBody } from "react-bootstrap";
import toast from "react-hot-toast";
import "./GeneralSettings.scss";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";

const AutoBonus = ({ formik }) => {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [state, setState] = useState({
    isLoading: false,
    isLoadingGetData: false,
    warningMessage: "",
    switch: true,
  });

  const onChangeWarning = (e) => {
    setState({
      ...state,
      warningMessage: e?.target?.value,
    });
  };

  const onChangeBonusSwitch = () => {
    if (formik?.values?.autoBonusChechBox) {
      formik.setFieldValue("autoBonusChechBox", false);
    } else {
      formik.setFieldValue("autoBonusChechBox", true);
    }
  };
  return (
    <>
      <div className="auto_bonus_container contain">
        <div className="text_heading text-muted font-weight-normal">
          <p>New User Auto Bonus</p>
          <Switch
            {...label}
            onChange={onChangeBonusSwitch}
            checked={formik?.values?.autoBonusChechBox}
          />
        </div>
        <div className="body_container commission_referral_body">
          <div className="number_input_container">
            <label>Bonus amount</label>
            <div className="input_and_icon">
              <input
                className="number_input border"
                placeholder="Enter your bonus amount"
                value={formik?.values?.autoBonusAmount}
                onChange={formik?.handleChange}
                name="autoBonusAmount"
                type="number"
              ></input>
              <div className="percentage_icon">
                <CurrencyRupeeOutlinedIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AutoBonus;
