import { CircularProgress, Switch, TextareaAutosize } from "@mui/material";
import React, { useState } from "react";
import CreateGameModal from "./CreateGameModal";
import EditGameModal from "./EditGameModal";
import "./GameManager.scss";
import GamesList from "./GamesList";

const GameManager = ({ formik }) => {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [state, setState] = useState({
    isLoading: false,
  });

  return (
    <>
      {state?.isLoading ? (
        <div className="loading_container">
          <CircularProgress thickness={3} style={{ width: 40, height: 40 }} />
          <p>Fetching data...</p>
        </div>
      ) : (
        <div>
          <GamesList />
        </div>
      )}
      <CreateGameModal />
      <EditGameModal />
    </>
  );
};

export default GameManager;
