import { CircularProgress, Switch, TextareaAutosize } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Toast, ToastBody } from "react-bootstrap";
import toast from "react-hot-toast";
import "./GeneralSettings.scss";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";

const CommissionAndReferral = ({ formik }) => {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [state, setState] = useState({
    isLoading: false,
    isLoadingGetData: false,
    warningMessage: "",
    switch: true,
  });

  const onChangeWarning = (e) => {
    setState({
      ...state,
      warningMessage: e?.target?.value,
    });
  };
  return (
    <>
      <div className="commission_container contain">
        <div className="text_heading text-muted font-weight-normal">
          <p>Commission & Referral</p>
        </div>
        <div className="body_container commission_referral_body">
          <div className="number_input_container">
            <label>Commission percentage</label>
            <div className="input_and_icon">
              <input
                className="number_input border"
                placeholder="Enter your commission"
                value={formik?.values?.commission}
                onChange={formik?.handleChange}
                name="commission"
                type="number"
              ></input>
              <div className="percentage_icon">
                <PercentOutlinedIcon />
              </div>
            </div>
          </div>
          <div className="number_input_container">
            <label>Referral percentage</label>
            <div className="input_and_icon">
              <input
                className="number_input border"
                placeholder="Enter your referral"
                value={formik?.values?.referral}
                name="referral"
                onChange={formik?.handleChange}
                type="number"
              ></input>
              <div className="percentage_icon">
                <PercentOutlinedIcon />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="referral_container">
        <div className="text_heading text-muted font-weight-normal">
          <p>Referral</p>
        </div>
        <div className="body_container contain">
          <div className="number_input_container">
            <input
              className="number_input border"
              placeholder="Enter your referral"
              value={formik?.values?.referral}
              name="referral"
              onChange={formik?.handleChange}
              type="number"
            ></input>
            <div className="percentage_icon">
              <PercentOutlinedIcon />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default CommissionAndReferral;
