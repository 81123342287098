import { CircularProgress, Switch, TextareaAutosize } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Toast, ToastBody } from "react-bootstrap";
import toast from "react-hot-toast";
import CommissionAndReferral from "./CommissionAndReferral";
import "./GeneralSettings.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import WithdrawAndDeposits from "./WithdrawAndDeposits";
import AutoBonus from "./AutoBonus";
import GameBattleLimit from "./GameBattleLimit";
import { ManageWebAppApiServices } from "../../../../utils/api/ManageWebAppApiServices";
import { ManualPayment } from "../../../../utils/api/ManualPayment";

const GeneralSettings = () => {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [state, setState] = useState({
    isLoading: false,
    isLoadingGetData: false,
    warningMessage: "",
    switch: true,
    settingsData: null,
  });

  const initialValues = {
    commission: 0,
    referral: 0,
    withdrawChechBox: false,
    withdrawLimit: 0,
    withdrawMassage: "",
    withdrawAmount: 0,

    depositChechBox: false,
    depositMassage: "",

    autoBonusChechBox: false,
    autoBonusAmount: 0,
    gameBattleLimit: 0,
  };

  // const onChangeWarning = (e) => {
  //   setState({
  //     ...state,
  //     warningMessage: e?.target?.value,
  //   });
  // };

  const saveCommissionSettings = async (values) => {
    const updateData = {
      commission_percents: values?.commission,
      setting_type: "commission",
    };

    const res = await ManageWebAppApiServices?.updateWebSettings(updateData);

    if (res?.data?.success) {
      // toast.success("Commission settings are updated successfully!");

      return {
        success: true,
      };
    } else {
      toast.error(res?.data?.data.message || "Internal server error!");
      return {
        success: false,
      };
    }
  };

  const saveReferralSettings = async (values) => {
    const updateData = {
      referral_percents: values?.referral,
      setting_type: "referrals",
    };

    const res = await ManageWebAppApiServices?.updateWebSettings(updateData);

    if (res?.data?.success) {
      // toast.success("Referrals settings are updated successfully!");
      return {
        success: true,
      };
    } else {
      toast.error(res?.data?.data.message || "Internal server error!");
      return {
        success: false,
      };
    }
  };

  const saveWithdrawalSettings = async (values) => {
    const updateData = {
      withdrawal_limit: values?.withdrawLimit,
      withdrawal_amount: values?.withdrawAmount,
      enable: values?.withdrawChechBox,
      message: values?.withdrawMassage,
      setting_type: "withdrawal",
    };

    const res = await ManageWebAppApiServices?.updateWebSettings(updateData);

    if (res?.data?.success) {
      // toast.success("Withdrawal settings are updated successfully!");
      return {
        success: true,
      };
    } else {
      toast.error(res?.data?.data.message || "Internal server error!");
      return {
        success: false,
      };
    }
  };
  const saveDepositSettings = async (values) => {
    const updateData = {
      enable: values?.depositChechBox,
      message: values?.depositMassage,
      setting_type: "deposit",
    };

    const res = await ManageWebAppApiServices?.updateWebSettings(updateData);

    if (res?.data?.success) {
      // toast.success("Deposit settings are updated successfully!");
      return {
        success: true,
      };
    } else {
      toast.error(res?.data?.data.message || "Internal server error!");
      return {
        success: false,
      };
    }
  };

  const saveGameBattleLimitSettings = async (values) => {
    const updateData = {
      game_battle_limit: values?.gameBattleLimit,
      setting_type: "game_battle_limit",
    };

    const res = await ManageWebAppApiServices?.updateWebSettings(updateData);

    if (res?.data?.success) {
      // toast.success("Game battle limit settings are updated successfully!");
      return {
        success: true,
      };
    } else {
      toast.error(res?.data?.data.message || "Internal server error!");
      return {
        success: false,
      };
    }
  };
  const saveNewUserAutoBonusSettings = async (values) => {
    const updateData = {
      enable: values?.autoBonusChechBox,
      bonus_amount: values?.autoBonusAmount,
      setting_type: "new_user_auto_bonus",
    };

    const res = await ManageWebAppApiServices?.updateWebSettings(updateData);

    if (res?.data?.success) {
      // toast.success("Auto bonus settings are updated successfully!");
      return {
        success: true,
      };
    } else {
      toast.error(res?.data?.data.message || "Internal server error!");
      return {
        success: false,
      };
    }
  };

  const onSubmitHandler = async (values) => {
    setState({
      ...state,
      isLoading: true,
    });

    const res = await Promise.all([
      saveCommissionSettings(values),
      saveReferralSettings(values),
      saveWithdrawalSettings(values),
      saveDepositSettings(values),
      saveGameBattleLimitSettings(values),
      saveNewUserAutoBonusSettings(values),
    ]);

    let successCheck =
      res[0].success &&
      res[1].success &&
      res[2].success &&
      res[3].success &&
      res[4].success &&
      res[5].success;

    if (successCheck) {
      toast.success("Settings are updated successfully!");
    }

    setState({
      ...state,
      isLoading: false,
    });
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      commission: Yup.number().optional(),
      referral: Yup.number().optional(),
      withdrawChechBox: Yup.boolean().optional(),
      withdrawLimit: Yup.number().optional(),
      withdrawMassage: Yup.string().optional(),
      withdrawAmount: Yup.number().optional(),

      depositChechBox: Yup.boolean().optional(),
      depositMassage: Yup.string().optional(),

      autoBonusChechBox: Yup.boolean().optional(),
      autoBonusAmount: Yup.number().optional(),
      gameBattleLimit: Yup.number().optional(),
    }),
    onSubmit: onSubmitHandler,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });

  const setFormikData = (data) => {
    let withdrawal_metadata = data?.withdrawal?.metadata;
    let deposit_metadata = data?.deposit?.metadata;
    let bonus_metadata = data?.new_user_auto_bonus?.metadata;

    formik.setFieldValue(
      "commission",
      data?.commission?.metadata?.commission_percentage
    );

    formik.setFieldValue(
      "referral",
      data?.referrals?.metadata?.referral_percents
    );

    formik.setFieldValue("withdrawChechBox", withdrawal_metadata.enable);
    formik.setFieldValue(
      "withdrawLimit",
      withdrawal_metadata?.withdrawal_limit
    );
    formik.setFieldValue("withdrawMassage", withdrawal_metadata?.message);
    formik.setFieldValue(
      "withdrawAmount",
      withdrawal_metadata?.withdrawal_amount
    );

    formik.setFieldValue("depositChechBox", deposit_metadata.enable);
    formik.setFieldValue("depositMassage", deposit_metadata.message);

    formik.setFieldValue("autoBonusChechBox", bonus_metadata.enable);
    formik.setFieldValue("autoBonusAmount", bonus_metadata.bonus_amount);

    formik.setFieldValue(
      "gameBattleLimit",
      data?.game_battle_limit?.metadata?.game_battle_limit
    );
  };

  const getDataApi = async () => {
    setState({
      ...state,
      isLoadingGetData: true,
    });
    const res = await ManualPayment?.getWebAppSettings();

    console.log("state-res>>>", res);
    if (res?.data?.success) {
      setState({
        ...state,
        isLoadingGetData: false,
        settingsData: res?.data?.data,
      });
      setFormikData(res?.data?.data);
    } else {
      toast.error(res?.data?.data?.message || "Internal server error!");
    }
  };

  useEffect(() => {
    getDataApi();
  }, []);

  return (
    <>
      {state?.isLoadingGetData ? (
        <div className="loading_container">
          <CircularProgress thickness={4} style={{ width: 50, height: 50 }} />
          <p>Fetching settings...</p>
        </div>
      ) : (
        <div className="settings_main_container">
          <form onSubmit={formik.handleSubmit}>
            <CommissionAndReferral formik={formik} />
            <WithdrawAndDeposits formik={formik} />
            <AutoBonus formik={formik} />
            <GameBattleLimit formik={formik} />
            <div className="button_container">
              <button type="submit">
                {state.isLoading ? (
                  <CircularProgress
                    thickness={4}
                    style={{ width: 30, height: 30 }}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default GeneralSettings;
