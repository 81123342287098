import axiosInstance from "./Axios";

export class GameApiServices {
  static getGames = async (page) => {
    try {
      const res = await axiosInstance({
        method: "get",
        url: "api/system-games",
        params: {
          page: 1,
          limit: 30,
          created_at: "desc",
        },
      });

      return {
        data: res.data,
      };
    } catch (err) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static createGame = async (data) => {
    try {
      const res = await axiosInstance({
        method: "post",
        url: "api/system-games",
        data,
      });

      return {
        data: res.data,
      };
    } catch (err) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static updateGame = async (data, game_id) => {
    try {
      const res = await axiosInstance({
        method: "put",
        url: `api/system-games/${game_id}`,
        data,
      });

      return {
        data: res.data,
      };
    } catch (err) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static deleteGames = async (game_id) => {
    try {
      const res = await axiosInstance({
        method: "delete",
        url: `api/system-games/${game_id}`,
      });

      return {
        data: res.data,
      };
    } catch (err) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };
}
