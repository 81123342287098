import axiosInstance from "./Axios";

export class ManualPayment {
  static getManualPayments = async ({
    page,
    limit = 20,
    search = "",
    status = "",
  }) => {
    try {
      const res = await axiosInstance({
        method: "get",
        url: "api/manual-payments",
        params: {
          page,
          limit,
          search: search?.trim(),
          status,
          created_at: "desc",
        },
      });

      return {
        data: res.data,
      };
    } catch (err) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static updatePaymentStatus = async (data) => {
    try {
      const res = await axiosInstance({
        method: "post",
        url: "api/manual-payments/update-payment-status",
        data,
      });

      return {
        data: res.data,
      };
    } catch (err) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static getWebAppSettings = async () => {
    try {
      const res = await axiosInstance({
        method: "get",
        url: "api/web-app-settings",
      });

      return {
        data: res.data,
      };
    } catch (err) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static updatePaymentSetting = async (data) => {
    try {
      const res = await axiosInstance({
        method: "post",
        url: "api/web-app-settings/payment-settings",
        data,
      });

      return {
        data: res.data,
      };
    } catch (err) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static deleteQrImage = async () => {
    try {
      const res = await axiosInstance({
        method: "delete",
        url: "api/manual-payments/payment-settings/delete-qr",
      });

      return {
        data: res.data,
      };
    } catch (err) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static rejectDepositPayment = async ({ transaction_id }) => {
    try {
      const res = await axiosInstance({
        method: "post",
        url: "deposit/reject",
        data: {
          transaction_id,
        },
      });

      return {
        data: res?.data,
        success: true,
      };
    } catch (err) {
      return {
        error: "Something went wrong !",
        data: err?.response,
        success: false,
      };
    }
  };

  static approveDepositPayment = async ({ transaction_id, user_id }) => {
    try {
      const res = await axiosInstance({
        method: "post",
        url: "deposit/approve",
        data: {
          transaction_id,
          user_id,
        },
      });

      return {
        data: res?.data,
        success: true,
      };
    } catch (err) {
      return {
        error: "Something went wrong!",
        data: err?.response,
        success: false,
      };
    }
  };

  // static updateWarning = async (data) => {
  //   try {
  //     const res = await axiosInstance({
  //       method: "post",
  //       url: "api/web-app-settings/waring/update",
  //       data,
  //     });

  //     return {
  //       data: res.data,
  //     };
  //   } catch (err) {
  //     return {
  //       error: "Something went wrong !",
  //       data: err?.response,
  //     };
  //   }
  // };
}
