import "./ManualPaymentsList.scss";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import PaymentActionBtn from "./PaymentActionBtn";
import { ManageWebAppApiServices } from "../../../utils/api/ManageWebAppApiServices";
import toast from "react-hot-toast";
import { CircularProgress } from "@mui/material";
import { ManualPayment } from "../../../utils/api/ManualPayment";
import {
  getPaymentScreenshotUrl,
  PaymentStatus,
} from "../../../utils/common.utils";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

let searchTimeOut = null;

const ManualPaymentsList = () => {
  const [state, setState] = useState({
    isLoading: false,
    listData: [],
    totalPage: 0,
    currentPage: 1,
  });

  const [filterState, setFilterState] = useState({
    searchText: "",
    status: "",
    limit: 30,
  });

  const resetPaymentList = useSelector(
    (state) => state?.gameManager?.resetPaymentList
  );

  const getDate = (dateString) => {
    let date = moment(dateString).format("MMM Do YY");
    return date;
  };

  const getScreenshotLink = (linkString) => {
    let updateLink = linkString;

    if (linkString?.length > 20) {
      let result = updateLink?.slice(0, 20);
      updateLink = `${result}...`;
    }

    return updateLink;
  };

  const getDataApi = async ({ page, status, search, limit }) => {
    setState({
      ...state,
      isLoading: true,
    });
    const res = await ManualPayment?.getManualPayments({
      page,
      limit,
      search,
      status,
    });

    console.log("state-res>>>", res);
    if (res?.data?.success) {
      setState({
        ...state,
        listData: res?.data.data?.docs,
        isLoading: false,
        totalPage: res?.data.data?.totalPages,
      });
    } else {
      // alert("Internal server error!");
      toast.error("Internal server error!");
    }
  };

  const statusBadge = (status) => {
    switch (status) {
      case PaymentStatus.APPROVED:
        return (
          <div className="badge badge-outline-success text-capitalize">
            {status}
          </div>
        );
        break;
      case PaymentStatus.APPROVED:
        return (
          <div className="badge badge-outline-warning text-capitalize">
            {status}
          </div>
        );
        break;
      default:
        return (
          <div className="badge badge-outline-danger text-capitalize">
            {status}
          </div>
        );
    }
  };

  const handlePageClick = (data) => {
    console.log("page>>>", data.selected);
    let currentPage = data.selected + 1;
    setState({
      ...state,
      currentPage,
    });
  };

  useEffect(() => {
    getDataApi({
      page: state?.currentPage,
      limit: filterState.limit,
      search: filterState.searchText,
      status: filterState.status,
    });
  }, [resetPaymentList, state?.currentPage]);

  console.log("state>>", state?.totalPage);

  const handleSearch = (e) => {
    let key = e.target.value;

    setFilterState({
      ...filterState,
      searchText: key,
    });
  };

  const searchByStatus = (event) => {
    let key = event.target.value;

    console.log("key", key);

    if (!key || key == 0) {
      key = "";
    }

    setFilterState({
      ...filterState,
      status: key,
    });
  };

  const handleLimitChange = (e) => {
    let key = e.target.value;

    setFilterState({
      ...filterState,
      limit: key,
    });
  };

  useEffect(() => {
    if (searchTimeOut) clearTimeout(searchTimeOut);

    searchTimeOut = setTimeout(() => {
      getDataApi({
        page: state?.currentPage,
        limit: filterState.limit,
        search: filterState.searchText,
        status: filterState.status,
      });
    }, 400);
  }, [filterState]);

  return (
    <div className="col-12 grid-margin">
      <h4 className="card-title text-dark table_heading">Payments list</h4>

      <div className="row justify-content-between">
        <div className="col-sm-6 d-flex">
          <input
            type="text"
            placeholder="Search..."
            // className="form-control col-sm-4 m-2"
            className="form-control"
            onChange={handleSearch}
          />
          <h5 className="mr-1 ml-2">Or</h5>
          <select
            className="form-control"
            id="findByStatus"
            name="findByStatus"
            onChange={searchByStatus}
          >
            <option value="0">Search Status</option>
            <option value={PaymentStatus.APPROVED}>Approved</option>
            <option value={PaymentStatus.PENDING}>Pending</option>
            <option value={PaymentStatus.REJECTED}>Rejected</option>
          </select>
        </div>
        <select
          className="form-control col-sm-1 m-2 bg-dark text-light"
          id="pagelimit"
          name="pagelimit"
          onChange={handleLimitChange}
        >
          <option value="10">Set limit</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="500">500</option>
        </select>
      </div>

      {state?.isLoading ? (
        <div className="loading_container">
          <CircularProgress thickness={3} style={{ width: 40, height: 40 }} />
          <p>Fetching data...</p>
        </div>
      ) : (
        <>
          <div className="card ">
            <div className="card-body text-light table_container p-0">
              {/* <h4 className="card-title text-dark">Payments list</h4> */}
              <div className="table-responsive">
                <table className="table manual_payment_table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th> ID</th>
                      <th> Date</th>
                      <th> Amount </th>
                      <th> Transaction ID</th>
                      <th> Mobile Number </th>
                      <th> Screenshot </th>
                      <th> Status </th>
                      <th> Action </th>
                    </tr>
                  </thead>
                  <tbody>
                    {state?.listData?.map((payment, key) => (
                      <tr key={payment?._id}>
                        <td>{key + 1}</td>
                        <td>{payment._id}</td>
                        <td>{getDate(payment?.updatedAt)}</td>
                        <td>{payment.amount}</td>
                        <td>{payment.transaction_id}</td>
                        <td>{payment?.user?.Phone || "-"}</td>
                        <td>
                          <div className="payment_screenshot">
                            {payment?.payment_screenshot ? (
                              <a
                                href={getPaymentScreenshotUrl(
                                  payment?.payment_screenshot
                                )}
                                target="_blank"
                              >
                                {getScreenshotLink(
                                  getPaymentScreenshotUrl(
                                    payment?.payment_screenshot
                                  )
                                )}
                              </a>
                            ) : (
                              "-"
                            )}
                          </div>
                        </td>
                        <td>{statusBadge(payment?.status)}</td>
                        <td>
                          <PaymentActionBtn
                            status={payment?.status}
                            id={payment?._id}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}

      {state?.totalPage !== 1 && !state?.isLoading ? (
        <div className="mt-4">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={state?.totalPage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ManualPaymentsList;

const paymentData = [
  {
    _id: "65dcee76f23f9da950045fd1",
    date: "2024-02-26T20:03:02.468Z",
    amount: "200",
    screenshot:
      "https://mylo-global-dev-storage.s3.amazonaws.com/attachments/avatars//019570e98f18717246e8e8e9132c5b8f-fed57696-6d18-40e2-8a6f-5c0c8beb1713",
    action: "",
    action_type: "Pending",
  },

  {
    _id: "65dcee76f23f9da950045fd2",
    date: "2024-02-26T20:03:02.468Z",
    amount: "200",
    screenshot:
      "https://mylo-global-dev-storage.s3.amazonaws.com/attachments/avatars//019570e98f18717246e8e8e9132c5b8f-fed57696-6d18-40e2-8a6f-5c0c8beb1713",
    action: "",
    action_type: "Pending",
  },

  {
    _id: "65dcee76f23f9da950045fd3",
    date: "2024-02-26T20:03:02.468Z",
    amount: "200",
    screenshot:
      "https://mylo-global-dev-storage.s3.amazonaws.com/attachments/avatars//019570e98f18717246e8e8e9132c5b8f-fed57696-6d18-40e2-8a6f-5c0c8beb1713",
    action: "",
    action_type: "Pending",
  },

  {
    _id: "65dcee76f23f9da950045fd4",
    date: "2024-02-26T20:03:02.468Z",
    amount: "200",
    screenshot:
      "https://mylo-global-dev-storage.s3.amazonaws.com/attachments/avatars//019570e98f18717246e8e8e9132c5b8f-fed57696-6d18-40e2-8a6f-5c0c8beb1713",
    action: "",
    action_type: "Pending",
  },
];
